import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Contact from './Contact';

const HelloText = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <Container className='Hellotext-main-container'>
      <Row className='hello-row'>
        <Col className='hello-col'>
          <h1 id='h1-media'>Hi,</h1>
          <h1 id='h1-media'>
            I'm <span className='highlight'>Raj,</span>
          </h1>
          <h1 id='h1-media'>Full Stack developer</h1>
          <h6>Experience working with MernStack , WordPress and Flutter</h6>
          <Button id='button-col' onClick={() => setModalShow(true)}>
            Contact Me
          </Button>
          <Contact show={modalShow} onHide={() => setModalShow(false)} />
        </Col>
      </Row>
    </Container>
  );
};

export default HelloText;
