import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './LoaderPage.module.css';

const LoaderPage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default LoaderPage;
