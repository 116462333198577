import React, { useState, useEffect } from 'react';
import HomePage from './screens/HomePage';
import LoaderPage from './screens/LoaderPage';

function App() {
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return <>{Loading ? <LoaderPage /> : <HomePage />}</>;
}

export default App;
