import React, { useState } from 'react';
import { Modal, Button, Row, Form } from 'react-bootstrap';
import emailjs from 'emailjs-com';

const Result = () => {
  return (
    <p>Your message has been successfully sent. I will contact you soon. </p>
  );
};

const Contact = (props) => {
  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_hb5tuyh',
        'template_bz0ltmf',
        e.target,
        'user_2tLXmeos07cIKFCxqWlbF'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };

  setTimeout(() => {
    showResult(false);
  }, 5000);

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body className='modal-wrapper'>
        <h1>Lets talk</h1>
        <Form onSubmit={sendEmail}>
          <Row className='input-row-style'>
            <Form.Control
              type='text'
              name='fullName'
              placeholder='Full Name'
              className='input-field'
            />
          </Row>
          <Row className='input-row-style'>
            <Form.Control
              type='text'
              name='subject'
              placeholder='Subject'
              className='input-field'
            />
          </Row>

          <Row className='input-row-style'>
            <Form.Control
              type='email'
              name='email'
              placeholder='Enter email'
              className='input-field'
            />
          </Row>

          <Row className='input-row-style'>
            <Form.Control
              as='textarea'
              name='message'
              placeholder='Message'
              rows={4}
              className='input-field'
            />
          </Row>

          <Row className='input-row-style'>
            <Button variant='primary' type='submit' className='btn-contactus'>
              Submit
            </Button>
          </Row>
          <Row>{result ? <Result /> : null}</Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Contact;
