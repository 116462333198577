import React, { Suspense } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import HelloText from '../components/HelloText';
import HeroImage from '../components/HeroImage';
// import MyselfSection from '../components/MyselfSection';
// import Feedback from '../components/Feedback';
// import ProundSection from '../components/ProundSection';
// import Showcase from '../components/Showcase';
// import ShowcaseFifth from '../components/ShowcaseFifth';
// import ShowcaseForth from '../components/ShowcaseForth';
// import ShowcaseSecond from '../components/ShowcaseSecond';
// import ShowcaseThird from '../components/ShowcaseThird';
// import Footer from '../components/Footer';
import jenifer from '../images/jenifer.png';
import lee from '../images/lee.jpeg';
import sir from '../images/sir.jpg';
import sonam from '../images/sonam.jpeg';
import desmond from '../images/desmond.jpeg';

const MyselfSection = React.lazy(() => import('../components/MyselfSection'));
const Feedback = React.lazy(() => import('../components/Feedback'));
const Showcase = React.lazy(() => import('../components/Showcase'));
const Footer = React.lazy(() => import('../components/Footer'));
const ShowcaseFifth = React.lazy(() => import('../components/ShowcaseFifth'));
const ShowcaseForth = React.lazy(() => import('../components/ShowcaseForth'));
const ShowcaseSecond = React.lazy(() => import('../components/ShowcaseSecond'));
const ShowcaseThird = React.lazy(() => import('../components/ShowcaseThird'));
const ProundSection = React.lazy(() => import('../components/ProundSection'));

const HomePage = () => {
  return (
    <Container fluid className='fluid-fix'>
      <Header />
      <HelloText />
      <HeroImage />
      <Suspense fallback={<div> Loading....</div>}>
        <MyselfSection />
        <ProundSection />
        <Showcase />
        <Feedback
          feedbackphoto={jenifer}
          feedbacktext={
            "Raj designed a bespoke, easy to search and intuitive members' capability directory for our website. Working with Raj was a great experience and the end product continues to attract positive feedback."
          }
          role={'CEO at Advanced Fibre Cluster Geelong'}
        />
        <ShowcaseSecond />
        <Feedback
          feedbackphoto={sonam}
          feedbacktext={
            'Raj was a real pleasure to work with and we look forward to working with him again. He’s definitely the kind of designer you can trust with a project from start to finish.'
          }
          role={'Owner at Ghar Aangan'}
        />
        <ShowcaseThird />
        <Feedback
          feedbackphoto={desmond}
          feedbacktext={
            'Raj did a great job developing a fintech app. He was able to the design the app as expected. He clearly master his flutter app development skills.'
          }
          role={'Lecturer at Deakin University'}
        />
        <ShowcaseForth />
        <Feedback
          feedbackphoto={sir}
          feedbacktext={
            'Raj made a very userfriendly light app. App does its job of tipping and splitting and is free from crash. I am impressed by the simple but creative design.'
          }
          role={'Senior Data Analyst at DXC'}
        />
        <ShowcaseFifth />
        <Feedback
          feedbackphoto={lee}
          feedbacktext={
            'Brilliant digital creative mind, working with Raj was a great experience from start to end. Outcome of this project is excellent. Would highly recommend to any business in need of a site!'
          }
          role={'Personal project'}
        />
        <Footer />
      </Suspense>
    </Container>
  );
};

export default HomePage;
