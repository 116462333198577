import React from 'react';
import { Navbar, Image } from 'react-bootstrap';
import logo from '../images/rjlogo.png';

const Header = () => {
  return (
    <>
      <Navbar className='navbar-main'>
        <Navbar.Brand>
          {' '}
          <Image src={logo} id='logo-image' />
        </Navbar.Brand>
      </Navbar>
    </>
  );
};

export default Header;
