import React from 'react';
import { Container, Image } from 'react-bootstrap';

import background from '../images/background.jpg';

const HeroImage = () => {
  return (
    <Container fluid className='hero-video-container'>
      <Image id='video' src={background} />

      <div className='overlay'> </div>
    </Container>
  );
};

export default HeroImage;
